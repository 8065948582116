<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout column fill-height>
		<v-layout v-if="pageLoading" align-center fill-height justify-center>
			<v-progress-circular color="primary" indeterminate size="50" width="6" />
		</v-layout>
		<template v-else>
			<v-flex shrink>
				<v-toolbar dense>
					<w-search-input v-model="filters.search" :placeholder="$t('sa.products.search_for_a_product')" />
					<v-spacer />
					<v-divider vertical />
					<w-btn icon="add" :mini="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" @click="launchProductCreation()">{{
						$t('sa.products.create_a_product')
					}}</w-btn>
				</v-toolbar>
			</v-flex>
			<v-flex scroll-y>
				<v-layout justify-center row mt-2>
					<v-flex shrink style="width: 750px">
						<v-data-table
							class="elevation-1"
							:headers="headers"
							:items="products"
							:pagination="pagination"
							:loading="productsLoading"
							:no-data-text="noDataText"
							:total-items="pagination.totalItems"
							@click.native.stop
							@update:pagination="onPaginationUpdated($event)"
						>
							<template v-slot:items="{ item }">
								<td
									v-html="$highlightMatching(item.name, filters.search)"
								>
								</td>
								<td class="text-xs-right">
									<v-flex align-right>
										<w-btn color="" flat icon="edit" mini @click="launchProductEdition(item)">{{ $t('sa.products.actions.modify') }}</w-btn>
										<w-btn-delete color="" flat mini :loading="item.deletion_loading" @click="launchProductDeletion(item)" />
									</v-flex>
								</td>
							</template>

						</v-data-table>
					</v-flex>
				</v-layout>
			</v-flex>
			<w-dialog
				v-model="showDialog"
				:max-width="$vuetify.breakpoint.xl ? '40vw' : '90vw'"
				:title="product?.id ? $t('sa.products.actions.modify_a_product') : $t('sa.products.actions.create')"
				@close="onProductDialogClosed()"
			>
				<ProductForm
					ref="form"
					v-model="product"
					:is-valid.sync="isValid"
					:error-messages="errorMessages"
				/>
				<template v-slot:actions>
					<v-spacer />
					<w-btn-save :disabled="!canSave" flat :loading="formLoading" @click="saveProduct()" />
				</template>
			</w-dialog>
		</template>
	</v-layout>
</template>

<script>
import debounce from 'debounce'
import SuperAdminProductModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminProductModuleGuard'

export default {
	name: 'ProductManager',
	components: {
		ProductForm: () => ({
			component: import('@/components/SuperAdmin/Products/ProductForm')
		})
	},
	mixins: [SuperAdminProductModuleGuard],
	data: function() {
		return {
			errorMessages: {
				name: null,
			},
			product: {
				id: null,
				modules: [],
				name: null,
			},
			initialProduct: {
				name: null,
				modules: [],
			},
			products: [],
			productsLoading: false,
			formLoading: false,
			filters: {
				search: '',
			},
			headers: [
				{
					sortable: true,
					text: this.$tc('sa.products.product', 1),
					value: 'name'
				},
				{
					sortable: false,
					text: "",
					value: 'actions'
				},
			],
			pageLoading: false,
			searchTimeout: null,
			showDialog: false,
			isValid: false,
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: 10,
				sortBy: 'name',
				totalItems: 0
			},
		}
	},
	computed: {
		noDataText: function() {
			if (this.accountingFirmsLoading) {
				return this.$t('sa.products.loading-progress');
			}
			if (this.filters.search) {
				return this.$t('sa.products.no-data-found-for-search', { search: this.filters.search });
			}
			return this.$t('sa.products.no-data-found');
		},
		productModules() {
			return [...this.product.modules]
				.map(module => module.id)
				.sort()
		},
		initialProductModules() {
			return [...this.initialProduct.modules]
				.map(module => module.id)
				.sort()
		},
		hasChangedModules() {
			if (this.productModules.length !== this.initialProductModules.length) {
				return true;
			}

			return JSON.stringify(this.productModules) !== JSON.stringify(this.initialProductModules);
		},
		hasChangedName() {
			return this.product.name !== this.initialProduct.name;
		},
		canSave() {
			if (this.product.id) {
				return this.isValid && (this.hasChangedModules || this.hasChangedName)
			}
			return this.isValid;
		}
	},
	watch: {
		'filters.search'() {
			this.onSearchModified()
		},
	},
	mounted: function() {
		this.loadPageData()
	},
	methods: {
		loadProducts: async function() {
			this.productsLoading = true
			const filters = {
				page: this.pagination.page,
				rowsPerPage: this.pagination.rowsPerPage,
				...(this.pagination.sortBy && { sort: (this.pagination.descending ? '-' : '+') + this.pagination.sortBy }),
				...(this.filters.search && { search: this.filters.search })
			}
			const response = await this.service.getProducts(filters);
			const products = 'pagination' in response ? response.data : response;
			if (response.hasOwnProperty('pagination')) {
				const pagination = response.pagination
				this.pagination.page = pagination.currentPage
				this.pagination.rowsPerPage = pagination.perPage
				this.pagination.totalItems = pagination.total
			} else {
				this.pagination.page = 1
				this.pagination.rowsPerPage = -1
				this.totalItems = response.length
			}
			this.products = products.map(product => ({
				...product,
				deletion_loading: false
			}));
			this.productsLoading = false
		},
		loadPageData: async function() {
			this.pageLoading = true
			await this.loadProducts();
			this.pageLoading = false;
		},
		onSearchModified: debounce(function() {
			this.loadProducts()
		}, 600),
		launchProductCreation: function() {
			this.$refs.form.resetValidation()
			this.resetProduct();
			this.showDialog = true
		},
		launchProductEdition: function(product) {
			this.$refs.form.resetValidation()
			this.product = {
				id: product.id,
				name: product.name,
				modules: [...product.modules],
			}
			this.initialProduct.modules = [...product.modules];
			this.initialProduct.name = product.name;
			this.showDialog = true
		},
		launchProductDeletion: async function(product) {
			const confirmed = await this.$dialog
				.warning({
					text: this.$t('sa.products.actions.are_you_sure'),
					title: this.$t('sa.products.actions.delete', { product: product.name }),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				});

			if (!confirmed) {
				return;
			}

			try {
				product.deletion_loading = true;
				await this.service.deleteProduct(product.id);
				this.products = this.products.filter(item => product.id !== item.id);
				this.resetProduct();
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.products.actions.deleted'));
			} finally {
				product.deletion_loading = false;
				this.refreshList();
			}
		},
		saveProduct: function() {
			if (this.product.id) {
				return this.replaceProduct()
			}
			return this.createProduct()
		},
		createProduct: async function() {
			this.formLoading = true;
			try {
				const product = await this.service.createProduct(this.product);
				this.products.push(product);
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.products.actions.created'));
				this.showDialog = false;
			} catch (error) {
				if (error.errors?.name) {
					this.errorMessages.name = error.errors.name[0];
				}
				this.isValid = false;
			} finally {
				this.formLoading = false;
				this.refreshList();
			}
		},
		replaceProduct: async function() {
			this.formLoading = true
			try {
				const product = await this.service.replaceProduct(this.product.id, this.product);
				this.products = this.products.map(item => (item.id === product.id ? product : item));
				this.initialProduct.modules = [...product.modules];
				this.initialProduct.name = product.name;
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.products.actions.modified'));
				this.showDialog = false;
			} catch (error) {
				if (error.errors?.name) {
					this.errorMessages.name = error.errors.name[0];
				}
				this.isValid = false;
			} finally {
				this.formLoading = false;
				this.refreshList();
			}
		},
		onPaginationUpdated: function(paginationData) {
			// eslint-disable-next-line no-unused-vars
			const { totalItems, ...restOfPaginationData } = paginationData;
			// eslint-disable-next-line no-unused-vars
			const { totalItems: currentTotal, ...currentPagination } = this.pagination;

			if (JSON.stringify(restOfPaginationData) === JSON.stringify(currentPagination)) {
				return;
			}
			this.pagination = { ...this.pagination, ...paginationData };
			this.loadProducts();
		},
		onProductDialogClosed: function() {
			this.resetProduct();
		},
		refreshList: function() {
			this.resetPagination()
			this.loadProducts()
		},
		resetPagination: function() {
			this.pagination.page = 1
		},
		resetProduct: function() {
			this.product.id = null;
			this.product.name = null;
			this.product.modules = [];

			this.initialProduct.name = null;
			this.initialProduct.modules = [];
		}
	}
}
</script>
